import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);


import cNav from "./components/c_nav"; //公共导航
import cFooter from "./components/c_footer"; //公共导航

Vue.component("cNav", cNav);
Vue.component("cFooter", cFooter);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
